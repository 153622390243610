import React from 'react';
import clsx from 'clsx';
import { makeStyles, Divider } from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import { GetStarted, Features, Reviews, QuickStart, Services, Hero } from './components';
import Image from 'material-ui-image'
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import MenuIcon from '@material-ui/icons/Menu';
import { Box, Typography, Button } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Parallax } from 'components/organisms';
import { SectionHeader } from 'components/molecules';
import { CardBase } from 'components/organisms';
import { DescriptionCta } from 'components/molecules';
import { Footer } from 'layouts/Main/components';
import Drawer from '@material-ui/core/Drawer';
import { Icon } from 'leaflet';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import Fab from '@material-ui/core/Fab';
import { positions } from '@material-ui/system';
import PowerIcon from '@material-ui/icons/Power';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';


const useStyles = makeStyles(() => ({
  sectionAlternateNoPaddingTop: {
    '& .section-alternate__content': {
      paddingBottom: 0,
    },
  },
  dividerSection: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  leftFloater: {
    float: 'left'
  },
  rightFloater: {
    float: 'right'
  },

  logo: {
    height: 100, 
    width: 400,
    marginLeft: 'auto', 
    marginRight: 'auto',
    marginTop: -50,
    display:'block',

  },
  paper: {
    width: 550
  }
}));

const subtitle = 'StrongEye helps companies to achieve better outcomes for their software development projects.';

const IndexView = ({ themeMode }) => {
  const [viewed, setViewed] = React.useState(false);
  const [lineHeight, setLineHeight] = React.useState(5);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box style={{height:70}}>  
      <Image src="/img/StrongEye-logo.svg" imageStyle={{ width: 320, height: 70 }} />
      </Box>
      <Divider />
      <List>
        <ListItem button key={'about'} >
          <ListItemIcon><DirectionsRunIcon /></ListItemIcon>
          <ListItemText primary={'About'} />
        </ListItem>
        <ListItem button key={'parttimecto'}>
          <ListItemIcon><DirectionsIcon /></ListItemIcon>
          <ListItemText primary={'Part Time CTO Services'} />
        </ListItem>
        <ListItem button key={'services'}>
          <ListItemIcon><SettingsApplicationsIcon /></ListItemIcon>
          <ListItemText primary={'Project Consulting Services'} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem button key={'contact'}>
          <ListItemIcon><PowerIcon /></ListItemIcon>
          <ListItemText primary={'Contact'} />
        </ListItem>
      </List>

    </div>
  );
  const didView = () => setViewed(true);

  const pages = {
    landings: {
      title: 'Landings',
      id: 'landing-pages',
      children: {
        services: {
          groupTitle: 'Services',
          pages: [
            {
              title: 'Consulting',
              href: '/',
            },
            {
              title: 'As-Needed CTO Services',
              href: '/',
            },
            {
              title: 'Software Project Leadership',
              href: '/',
            },
          ],
        },
        company: {
          groupTitle: 'Company',
          pages: [
            {
              title: 'About StrongEye',
              href: '/'
            },
            {
              title: 'Contact',
              href: '/contact'
            },
          ]
        },
      },
    },
    account: {
      title: 'Account',
      id: 'account',
      children: {
        settings: {
          groupTitle: 'Settings',
          pages: [
            {
              title: 'General',
              href: '/account/?pid=general',
            },
            {
              title: 'Security',
              href: '/account/?pid=security',
            },
            {
              title: 'Notifications',
              href: '/account/?pid=notifications',
            },
            {
              title: 'Billing',
              href: '/account/?pid=billing',
            },
          ],
        },
        signup: {
          groupTitle: 'Sign up',
          pages: [
            {
              title: 'Simple',
              href: '/signup-simple',
            },
            {
              title: 'Cover',
              href: '/signup-cover',
            },
          ],
        },
        signin: {
          groupTitle: 'Sign in',
          pages: [
            {
              title: 'Simple',
              href: '/signin-simple',
            },
            {
              title: 'Cover',
              href: '/signin-cover',
            },
          ],
        },
        password: {
          groupTitle: 'Password reset',
          pages: [
            {
              title: 'Simple',
              href: '/password-reset-simple',
            },
            {
              title: 'Cover',
              href: '/password-reset-cover',
            },
          ],
        },
        error: {
          groupTitle: 'Error',
          pages: [
            {
              title: 'Simple',
              href: '/not-found',
            },
            {
              title: 'Cover',
              href: '/not-found-cover',
            },
          ],
        },
      },
    },
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const classes = useStyles();

  const handlePeek = () => {
    if (lineHeight === 5) {
      setLineHeight(300);
    } else {
      setLineHeight(5);
    }
  };
  const handleHide = () => {
    setLineHeight(5);
  }

  useEffect(() => {
    const hasViewed = localStorage.getItem('viewedBefore') === 'true';
    if (!hasViewed) {
      localStorage.setItem('viewedBefore', 'true');
    } else {
      didView();
    }
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>StrongEye Inc., : On-demand technical leadership, fractional CTO consultancy</title>
        <meta name="description"
          content="On-demand technical leadership, software planning, and fractional CTO consulting services. In Seattle, WA since 2006."></meta>
      </Helmet>

      <Drawer classes={{ paper: classes.paper }} anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
   
        {list('left')}
      </Drawer>

      { viewed === false ? (
        <>
          <IconButton className={classes.leftFloater} onClick={toggleDrawer('left', true)}><MenuIcon /></IconButton>
          <Hero className={classes.rightFloater} themeMode={themeMode} />
        </>
      ) : (
          <>
            <IconButton className={classes.leftFloater} onClick={toggleDrawer('left', true)}><MenuIcon /></IconButton>
            <div className={classes.rightFloater} style={{height: 100, width:'100%', marginRight:'auto', marginLeft:'auto'}}>
              <img src="/img/StrongEye-logo.svg" className={classes.logo}/>
            </div>
              
  
          </>
        )}
      <Services />
     
      <Box marginBottom={10} padding={2} onClick={handlePeek} onMouseOut={handleHide}>
        <Parallax backgroundImage="/img/line.png" >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#007b3d3d' }}>
            <CardBase style={{ height: lineHeight, background: 'transparent' }}>
              <div> </div>
            </CardBase>
          </div>
        </Parallax>

      </Box>
      
      <SectionAlternate>
      <Features />
      </SectionAlternate>
      <Box marginBottom={2} display="flex" justifyContent="space-evenly" alignItems="center" padding={2} border="1px solid #ccc" borderRadius="4px">
        <DescriptionCta
          title="Let's chat about your project"
          subtitle="We're always happy to meet and learn about your project."
          primaryCta={<Button href="https://calendly.com/strongeye/introductory-meeting" variant="contained" color="primary" size="large">Book an intro</Button>}
          align="left"
        />
      </Box>
      <Footer pages={pages} />
    </div>
  );
};
/*

 secondaryCta={<Button variant="outlined" color="primary" size="large">Learn more about us</Button>}
         

Romavod
      <SectionAlternate className={classes.sectionAlternateNoPaddingTop}>
        <QuickStart />
      </SectionAlternate>
      <SectionAlternate>
        <Features />
      </SectionAlternate>
      <Section>
        <Reviews />
      </Section>
      <Section className={classes.dividerSection}>
        <Divider />
      </Section>
      <Section narrow>
        <GetStarted />
      </Section>
*/
export default IndexView;
