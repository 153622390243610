import React from 'react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, colors, makeStyles } from '@material-ui/core';
import { SectionHeader, IconAlternate } from 'components/molecules';
import { CardBase, DescriptionListIcon } from 'components/organisms';
import { Image } from 'components/atoms';

const useStyles = makeStyles(() => ({
  fontWeight900: {
    fontWeight: 900,
  },
}));

const data = [{
  icon: 'fas fa-cubes',
  img: 'Technical-Strategy-StrongEye.png',
  alt: 'Technical Strategy Consultant',
  color: colors.teal,
  title: 'Technical Strategy',
  subtitle: 'StrongEye provides technical strategy consulting.',
}, {
  icon: 'fas fa-palette',
  img: 'Project-Planning-StrongEye.png',
  alt: 'Software Project Planning Services',
  color: colors.teal,
  title: 'Software Project Planning',
  subtitle: 'StrongEye helps organize and plan your software project.',
}, {
  icon: 'fas fa-code',
  img: 'Software-architecture-Visualization-StrongEye.png',
  alt: 'Software Architecture Services',
  color: colors.teal,
  title: 'Software Architecture',
  subtitle: 'StrongEye can translate your requriements into a solid and lasting architecture.',
}, {
  icon: 'fas fa-moon',
  img: 'Software-Project-Estimation-StrongEye.png',
  alt: 'Software Project Estimation Services',
  color: colors.teal,
  title: 'Project Estimation',
  subtitle: 'StrongEye can create or validate estimation of schedule and budget, and can help you understand project risks based on the current plan.',
}, {
  icon: 'fas fa-rocket',
  img: 'Stakeholder-Communication-StrongEye.png',
  alt: 'Stakeholder Communication Services',
  color: colors.teal,
  title: 'Stakeholder Communication',
  subtitle: 'StrongEye can produce artifacts that help communicate software design to key stakeholders.',
}, {
  icon: 'fas fa-hand-holding-heart',
  img: 'Requirements-Modeling-Elicitation-StrongEye.png',
  alt: 'Requirements Elicitation and Semantic Modeling Services',
  color: colors.teal,
  title: 'Requirements Elicitation and Semantic Modeling Services',
  subtitle: 'StrongEye helps capture business requirements and policies in a form that reduces risk, speeds development and QA, and reduces rework.',
},
{
icon: 'fas fa-cubes',
img: 'Budget-Management-StrongEye.png',
alt: 'Software Project Budget Management Consultant',
color: colors.teal,
title: 'Software Project Budget Management',
subtitle: 'StrongEye helps right-size the scope and effort with your project budget, optimizing business value.',
},
{
  icon: 'fas fa-palette',
  img: 'Build-vs-Buy-Assessment-StrongEye.png',
  alt: 'Software Build vs Buy Assessment Consulting',
  color: colors.teal,
  title: 'Software Build vs Buy Assessment',
  subtitle: 'StrongEye can help evaluate options and provide trade-offs to support business decisions.',
}, 
{
  icon: 'fas fa-code',
  img: 'Offshore-Nearshore-Software-Vendor-Management.png',
  alt: 'Offshore and Nearshore Software Management Consultant',
  color: colors.teal,
  title: 'Offshore and Nearshore Software Management',
  subtitle: 'StrongEye can manage outsourced development teams, helping reduce risk and lower overall cost and schedule.',
}, 
{
  icon: 'fas fa-moon',
  img: 'Project-Rescue-StrongEye.png',
  alt: 'Software Project Rescue Consultant',
  color: colors.teal,
  title: 'Software Project Rescue',
  subtitle: 'Whatever the reason, your project needs expert help fast. StrongEye can work on a temporary basis to stabilize the effort and ensure it is once again in good hands.',
},
{
  icon: 'fas fa-rocket',
  img: 'Risk-Assessment-StrongEye.png',
  alt: 'Software Project Risk Assessment Consulting',
  color: colors.teal,
  title: 'Software Project Risk Assessment',
  subtitle: 'StrongEye applies rigor and experience to create matrix of risks for each project.',
},
 {
  icon: 'fas fa-hand-holding-heart',
  img: 'Scaling-Up-StrongEye.png',
  alt: 'Scaling Up Software System Consulting',
  color: colors.teal,
  title: 'Scaling Up',
  subtitle: 'StrongEye can help develop a roadmap for scaling your software from where it is to where it needs to be.',
}

];

const Features = ({ className, ...rest }) => {
  const classes = useStyles();
  const [vis, setVis] = useState(false);


  return (
    <div className={className} {...rest}>
      <SectionHeader
        title="Value-driven strategic + hands-on software project guidance"
        fadeUp
        titleProps={{
          variant: 'h3',
          color: 'textPrimary',
          className: classes.fontWeight900,
        }}
      />
      <Grid container spacing={2}>
        {data.map((adv, index) => (
          <Grid
            key={index}
            item
            container
            alignItems="center"
            direction="column"
            xs={6}
            md={4}
            data-aos="fade-up"
          >
            <CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `5px solid ${adv.color[500]}` }}
            >
              <Image src={"/img/Logo-Conceptual-Graphics/" + adv.img} alt={adv.alt} />
              </CardBase>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Features.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Features;
/*
<CardBase
              liftUp
              variant="outlined"
              style={{ borderTop: `5px solid ${adv.color[500]}` }}
            >
              </CardBase> 
              */